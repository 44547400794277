
export default {
  name: 'MegaMenuHamburger',

  props: {
    isNavbarOpen: {
      type: Boolean,
      default: false,
    },
  },
}
